import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";

import Masthead from "../components/strapi/structure/Masthead";
import Outrolocation from "../components/strapi/structure/OutroLocation";
import OneCol from "../components/strapi/structure/OneCol";
import TwoCol from "../components/strapi/structure/TwoCol";
import Commsupport from "../components/strapi/community/Commsupport";
import Commawards from "../components/strapi/community/Commawards";
import TestimonialSlider from "../components/testimonials/TestimonialSlider";

export default function Community({ data }) {
  return (
    <Layout>
      <SEO title="Community" />
      {data.allStrapiCommunity.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.comm_mh.mh_title}
            mhsubtitle={node.comm_mh.mh_subtitle}
            mhbackground={node.comm_mh.mh_bg.publicURL}
          />
          <OneCol
            title={node.comm_onecol.onecol_title}
            description={node.comm_onecol.onecol_description}
          />
          {/* <TwoCol 
            title={node.comm_responders.txtimg_title}
            description={node.comm_responders.txtimg_description}
            blueTitle={node.comm_responders.txtimg_bluetitle}
            blueLink={node.comm_responders.txtimg_bluelink}
            bgColor={node.comm_responders.txtimg_bgcolor}
            order={node.comm_responders.txtimg_order}
            rowimg={node.comm_responders.txtimg_img.publicURL}
        /> */}
          <Commsupport />
          <Commawards />

          <Outrolocation outro={node.comm_outro.location_description} />
          <TestimonialSlider />
        </React.Fragment>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query Community {
    allStrapiCommunity {
      edges {
        node {
          id # id of the node
          comm_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          comm_onecol {
            id
            onecol_title
            onecol_description
          }
          comm_responders {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_bluetitle
            txtimg_bluelink
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          comm_outro {
            id
            location_description
          }
        }
      }
    }
  }
`;
